import React, { useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import styles
import connect from '../../../assets/connect.png';
import './Connect.css';
import axios from 'axios';

const Connect = ({ connect_ref }) => {
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const locationRef = useRef();
  const reqRef = useRef();

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    
    // Name Validation
    if (!nameRef.current.value) {
      toast.error('Full Name is required');
      isValid = false;
    }

    // Email Validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRef.current.value) {
      toast.error('Email is required');
      isValid = false;
    } else if (!emailPattern.test(emailRef.current.value)) {
      toast.error('Please enter a valid email');
      isValid = false;
    }

    // Phone Validation
    if (!phoneRef.current.value) {
      toast.error('Phone number is required');
      isValid = false;
    } else if (phoneRef.current.value.length < 10) {
      toast.error('Phone number should be at least 10 digits');
      isValid = false;
    }

    // Location Validation
    if (!locationRef.current.value) {
      toast.error('Location is required');
      isValid = false;
    }

    // Requirements Validation
    if (!reqRef.current.value) {
      toast.error('Requirements are required');
      isValid = false;
    }

    return isValid;
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    // Validate form before submission
    if (!validateForm()) return;

    const postdata = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      location: locationRef.current.value,
      requirements: reqRef.current.value
    };

    setIsSubmitting(true); // Set submitting state to true

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sharerequirements`, postdata);

      if (response.status === 200) {
        toast.success('Message sent successfully!');
      }
    } catch (e) {
      toast.error('Something went wrong. Please try again.');
      console.error(e);
    } finally {
      setIsSubmitting(false); // Set submitting state to false when done
    }
  };

  return (
    <>
      <div className="LetsConnect" ref={connect_ref}>
        <div className="LC_content">
          <div>
            <div className="LC_head">Let's Connect</div>
            <div className="text">Please enter the following details</div>
          </div>
          <form onSubmit={sendEmail} className="LC_Inputs">
            <input 
              type="text" 
              ref={nameRef} 
              placeholder="Full Name*" 
              className="connectinput"
              required 
            />
            
            <input 
              type="text" 
              ref={emailRef} 
              placeholder="E Mail ID*" 
              className="connectinput" 
              required
            />

            <input 
              type="text" 
              ref={phoneRef} 
              placeholder="Phone Number*" 
              className="connectinput" 
              required
            />

            <input 
              type="text" 
              ref={locationRef} 
              placeholder="Location*" 
              className="connectinput" 
              required
            />

            <input 
              type="text" 
              ref={reqRef} 
              placeholder="Requirements*" 
              className="connectinput LC_reqinput"
              required 
            />

            <button className="LC_btn" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Submit'}
            </button>
          </form>
        </div>
        <div className="LC_imgDiv">
          <img src={connect} alt="connect" />
        </div>
      </div>

    
      <ToastContainer />
    </>
  );
};

export default Connect;
