import React, { useRef, useState, useEffect } from 'react'; 
import './ShareReq.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShareReq = () => {
  const [loading, setLoading] = useState(false);
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const locationRef = useRef();
  const reqRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postdata = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      location: locationRef.current.value,
      requirements: reqRef.current.value,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sharerequirements`, postdata);
      setLoading(false);
      console.log(response);
      if (response.data.success) {
        toast.success('Share successfully sent');
      } else {
        toast.error('Failed to Share');
      }
    } catch (error) {
      setLoading(false);
      toast.error('Failed to Share');
      console.error(error);
    }
  };

  return (
    <div className="ShareReq">
      <div className="LC_content">
        <div>
          <div className="SR_head">Share Requirements</div>
          <div className="text">Please enter the following details</div>
        </div>
        <form onSubmit={sendEmail} className="SR_Inputs">
          <input type="text" ref={nameRef} placeholder="Full Name*" className="SR_input" required />
          <input type="email" ref={emailRef} placeholder="E Mail ID*" className="SR_input" required />
          <input type="tel" ref={phoneRef} placeholder="Phone Number*" className="SR_input" required />
          <input type="text" ref={locationRef} placeholder="Location*" className="SR_input" required />
          <input type="text" ref={reqRef} placeholder="Requirements*" className="SR_input LC_reqinput" required />
          <button className="LC_btn" type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>

      {/* Toast container for showing toasts */}
      <ToastContainer />
    </div>
  );
};

export default ShareReq;
