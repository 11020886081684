import React, { useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const GetinTouch = ({ company }) => {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const [loading, setLoading] = useState(false); 

  const validateForm = () => {
    const name = nameRef.current.value.trim();
    const email = emailRef.current.value.trim();
    const phone = phoneRef.current.value.trim();

    if (!name || !email || !phone) {
      toast.error('All fields are required.');
      return false;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address.');
      return false;
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) {
      toast.error('Please enter a valid phone number (10 digits).');
      return false;
    }
    return true;
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    if (loading) return; 

    if (validateForm()) {
      const postdata = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        postedby: company.fullName,
        companyname: company.companyName,
        companyemail: company.email,
      };

      setLoading(true); 

      try {
        const data = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getintouch`,
          { postdata }
        );
        console.log(data);
        if (data.data != null) {
          toast.success('Successfully sent!');
        } else {
          toast.error('Something went wrong. Please try again.');
        }
      } catch (e) {
        console.log(e);
        toast.error('An error occurred. Please try again.');
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <div className="GetinTouchDiv">
      <div className="text">Please enter the following details</div>

      <form onSubmit={sendEmail} className="LC_Inputs">
        <input type="text" ref={nameRef} placeholder="Full Name*" className="GIT_input" required />
        <input type="text" ref={emailRef} placeholder="E Mail ID*" className="GIT_input" required />
        <input type="text" ref={phoneRef} placeholder="Phone Number*" className="GIT_input" required />

        <button
          className="getinTouchBtn"
          type="submit"
          disabled={loading} 
        >
          {loading ? 'Sending...' : 'Send'}
        </button>
      </form>

      {/* ToastContainer to display the notifications */}
      <ToastContainer />
    </div>
  );
};

export default GetinTouch;
