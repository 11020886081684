import React, { useState, useRef } from 'react';
import './Requirements.css';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Requirements() {
  const [showForm, setShowForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState('buy');
  const [loading, setLoading] = useState(false); // Loading state to manage submit button
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const locationRef = useRef();
  const reqRef = useRef();
  const companyNameRef = useRef();
  const serviceTypeRef = useRef();

  const services = [
    'Administrative Support', 'Facility Service', 'Housekeeping Services', 'Customer Service', 'Blue Collar', 'White Collar', 'Reception Service', 'Security Service', 'IT Support', 'Catering Service', 'AC/Telephone Repair', 'Electrician/Plumber Service', 'Mailroom Service', 'Pest Control', 'Office Boy', 'Logistics and Supply Chain', 'Marketing and Branding', 'Construction and Renovation', 'HVAC Services', 'Other'
  ];

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    
    setLoading(true); // Set loading state to true when submitting the form

    const postdata = selectedOption === 'buy' 
      ? { name: nameRef.current.value, email: emailRef.current.value, phone: phoneRef.current.value, location: locationRef.current.value, requirements: reqRef.current.value }
      : { companyName: companyNameRef.current.value, email: emailRef.current.value, phone: phoneRef.current.value, location: locationRef.current.value, serviceType: serviceTypeRef.current.value };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sharerequirements`, postdata);
      
      // If the request is successful, show a success notification
      toast.success("Your requirements have been submitted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      setShowForm(false); // Close the form after submission
    } catch (e) {
      // If the request fails, show an error notification
      toast.error("There was an error while submitting your requirements. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false); // Reset loading state after the submission process
    }
  };

  return (
    <div className='requirements-container'>
      {showForm && (
        <div className='modal2'>
          <div className='modal-content2'>
            <span onClick={handleButtonClick}>
              <CloseIcon />
            </span>
            <h2>Tell Us Your Requirements</h2>
            <div className='options'>
              <label>
                <input type="radio" name="type" value="buy" onChange={handleOptionChange} checked={selectedOption === 'buy'} /> Buy
              </label>
              <label>
                <input type="radio" name="type" value="sell" onChange={handleOptionChange} /> Sell
              </label>
            </div>
            {selectedOption === 'buy' && (
              <form onSubmit={sendEmail} className='SR_Inputs'>
                <input type="text" ref={nameRef} placeholder='Full Name*' className='SR_input'/>
                <input type="text" ref={emailRef} placeholder='E Mail ID*' className='SR_input'/>
                <input type="text" ref={phoneRef} placeholder='Phone Number*' className='SR_input'/>
                <input type="text" ref={locationRef} placeholder='Location*' className='SR_input'/>
                <input type="text" ref={reqRef} placeholder='Requirements*' className='SR_input LC_reqinput'/>
                <button className='LC_btn' type='submit' disabled={loading}> {loading ? 'Sending...' : 'Submit'}</button>
              </form>
            )}
            {selectedOption === 'sell' && (
              <form onSubmit={sendEmail} className='SR_Inputs'>
                <input type="text" ref={companyNameRef} placeholder='Company Name*' className='SR_input'/>
                <input type="text" ref={emailRef} placeholder='E Mail ID*' className='SR_input'/>
                <input type="text" ref={phoneRef} placeholder='Phone Number*' className='SR_input'/>
                <input type="text" ref={locationRef} placeholder='Location*' className='SR_input'/>
                <select ref={serviceTypeRef} className='SR_input'>
                  {services.map(service => (
                    <option key={service} value={service}>{service}</option>
                  ))}
                </select>
                <button className='LC_btn' type='submit' disabled={loading}> {loading ? 'Sending...' : 'Submit'}</button>
              </form>
            )}
          </div>
        </div>
      )}
      <button className='requirements' onClick={handleButtonClick}>Tell Us Your Requirements</button>

      <ToastContainer />
    </div>
  );
}

export default Requirements;
